import React from 'react';
import styled from 'styled-components';
import logo from './assets/logo.png';


const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #ffffff;
  color: #000000;
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 100px; 
  height: auto;
  margin-right: 10px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const TermsOfService = () => {
    return (
        <Container>
            <Header>
                <Logo src={logo} alt="1Point Logo" />
                <Title>Terms of Service</Title>
            </Header>

            <SectionTitle>1. Introduction</SectionTitle>
            <Paragraph>
                Welcome to 1Point, a loyalty points program that allows you to collect and redeem points for purchases made with our partners, who are small businesses. By using our services, you agree to these Terms of Service ("Terms"). Please read them carefully.
            </Paragraph>

            <SectionTitle>2. Eligibility</SectionTitle>
            <Paragraph>
                To use 1Point, you must be at least 13 years old and agree to provide accurate and complete information during the registration process. By creating an account, you represent and warrant that you meet these eligibility requirements.
            </Paragraph>

            <SectionTitle>3. Collecting Points</SectionTitle>
            <Paragraph>
                <strong>3.1 Earning Points:</strong> Points are earned when you make qualifying purchases with our partners. The number of points you earn per purchase will vary depending on the partner and the specific promotion at the time of purchase.
            </Paragraph>
            <Paragraph>
                <strong>3.2 Account:</strong> Points will be credited to your 1Point account once the purchase is confirmed. You are responsible for maintaining the security of your account and for any activities or actions under your account.
            </Paragraph>

            <SectionTitle>4. Redeeming Points</SectionTitle>
            <Paragraph>
                <strong>4.1 Redeeming:</strong> Points can be redeemed for rewards, discounts, or other offers as specified by our partners. The value and availability of rewards may vary.
            </Paragraph>
            <Paragraph>
                <strong>4.2 Expiration:</strong> Points may expire after a certain period of inactivity. We will notify you in advance if your points are about to expire.
            </Paragraph>
            <Paragraph>
                <strong>4.3 Non-Transferability:</strong> Points are non-transferable and cannot be redeemed for cash or sold.
            </Paragraph>

            <SectionTitle>5. User Obligations</SectionTitle>
            <Paragraph>
                <strong>5.1 Accurate Information:</strong> You agree to provide accurate and up-to-date information when registering for 1Point and to promptly update this information as necessary.
            </Paragraph>
            <Paragraph>
                <strong>5.2 Compliance:</strong> You agree to comply with all applicable laws and regulations when using 1Point. Misuse of the program, such as attempting to earn or redeem points fraudulently, will result in the termination of your account.
            </Paragraph>

            <SectionTitle>6. Data Collection and Privacy</SectionTitle>
            <Paragraph>
                <strong>6.1 Information We Collect:</strong> When you sign up for 1Point, we collect certain personal information, including your full name, phone number, email address, and IP address. This information is used to manage your account, process transactions, and improve our services.
            </Paragraph>
            <Paragraph>
                <strong>6.2 Data Use:</strong> Your information will be used in accordance with our [Privacy Policy](#) to provide you with a personalized experience, including sending promotional offers and updates about the program.
            </Paragraph>
            <Paragraph>
                <strong>6.3 Third-Party Sharing:</strong> We may share your information with our partners for the purpose of processing transactions and delivering rewards. We will not sell your personal information to third parties.
            </Paragraph>

            <SectionTitle>7. Termination</SectionTitle>
            <Paragraph>
                <strong>7.1 Termination by You:</strong> You may terminate your account at any time by contacting us at [Contact Information].
            </Paragraph>
            <Paragraph>
                <strong>7.2 Termination by Us:</strong> We reserve the right to suspend or terminate your account if we believe you have violated these Terms or engaged in fraudulent activity. Upon termination, any unused points will be forfeited.
            </Paragraph>

            <SectionTitle>8. Changes to Terms</SectionTitle>
            <Paragraph>
                We may update these Terms from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new Terms on our website or by other means. Your continued use of 1Point after the changes take effect will constitute your acceptance of the new Terms.
            </Paragraph>

            <SectionTitle>9. Limitation of Liability</SectionTitle>
            <Paragraph>
                To the maximum extent permitted by law, 1Point and its partners will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of the program.
            </Paragraph>

            <SectionTitle>10. Governing Law</SectionTitle>
            <Paragraph>
                These Terms are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or related to these Terms will be resolved in the courts of [Your Jurisdiction].
            </Paragraph>

            <SectionTitle>11. Contact Us</SectionTitle>
            <Paragraph>
                If you have any questions about these Terms, please contact us at:
            </Paragraph>
            <Paragraph>
                <strong>Email:</strong> 1point.loyalty@gmail.com
            </Paragraph>
        </Container>
    );
};

export default TermsOfService;
