import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import * as React from 'react'
import { Navigate } from 'react-router-dom';
import TermsOfService from './TermsOfService';
import LandingPage from './Landing';


function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/page/tc" Component={TermsOfService} />
          <Route path="/page/landing" Component={LandingPage} />
          <Route path="*" element={<Navigate replace to="/page/landing" />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
